/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    button {
        @apply duration-300;
    }

    .btn {
        @apply bg-secondary text-sm text-white hover:bg-lightgray rounded w-64 py-2 font-semibold px-2 cursor-pointer;
    }

    .btn:disabled {
        @apply !bg-lightgray;
    }

    .btn-secondary {
        @apply w-20 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 bg-gray-750;
    }

    .select {
        @apply hover:bg-secondary;
    }

    .btn-secondary-active {
        @apply hover:bg-secondary bg-lightgray;
    }

    .disabled:disabled {
        @apply bg-lightgray;
    }

    .btn-outline {
        @apply border px-4 py-2 text-xl;
    }

    .newCVs .w-60 .btn {
        @apply w-[150px];
    }

    .newCVs .w-60 {
        @apply w-[150px];
    }

    /* Apply one of the background options */
    .bg-circuit {
        background-color: #f9f9f9;
        background-image: linear-gradient(0deg, transparent 24%, rgba(0, 0, 0, 0.05) 25%, rgba(0, 0, 0, 0.05) 26%, transparent 40%, transparent 74%, rgba(0, 0, 0, 0.05) 75%, rgba(0, 0, 0, 0.05) 76%, transparent 77%, transparent),
                          linear-gradient(90deg, transparent 24%, rgba(0, 0, 0, 0.05) 25%, rgba(0, 0, 0, 0.05) 26%, transparent 40%, transparent 74%, rgba(0, 0, 0, 0.05) 75%, rgba(0, 0, 0, 0.05) 76%, transparent 77%, transparent);
        background-size: 50px 50px;
    }

    body {
        @apply bg-circuit;
    }
}

.bg-img {
    background-image: url('./assets/gradinet.png');
}

*::-webkit-scrollbar {
    display: none;
}

* {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

input[type=file],
input[type=file]::-webkit-file-upload-button {
    cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

/* New styles for CandidatesCards component */
.bg-gray-800 {
    background-color: #2d2d2d;
}

.bg-gray-900 {
    background-color: #1f1f1f;
}

.text-white {
    color: #ffffff;
}

.text-green-500 {
    color: #48bb78;
}

.text-yellow-500 {
    color: #ecc94b;
}

.text-red-500 {
    color: #f56565;
}
